import { Helmet } from "react-helmet"
import React from "react"

const SEO = props => {
  const { title, description, canonical, type } = props.page.seo
  // const description = props.page.seo.description
  // const url = props.page.seo.canonical
  const seo_twitter = "Airro Studio"
  const image = "https://airrostudio.com/logo.png"
//  const type = props.page.seo.type

  return (
    <Helmet defer={false}>

      <title>{title}</title>

      <meta name="image" content={image} />
      <meta name="description" content={description} />
      <link rel="canonical" href={canonical} />

      {/* OpenGraph tags */}
      <meta property="og:type" content={type} />
      <meta property="og:locale" content="en_US"></meta>
      <meta property="og:url" content={canonical} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={seo_twitter} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  )
}

export default SEO
